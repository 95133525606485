import state from './dashboard/state'
import getters from './dashboard/getters'
import mutations from './dashboard/mutations'
import actions from './dashboard/actions'
import summary from './summary'

export default {
  namespaced: true,
  modules: { summary },
  state: state(),
  getters,
  mutations,
  actions
}

import { take } from 'lodash'
import * as multi from '../../services'

export default {
  setData(state, { workbookData, headerIndex, meta }) {
    const headerLessData = workbookData.filter(
      (row, index) => index > headerIndex - 1
    )
    const tableHeaders = workbookData[headerIndex - 1].map(header => {
      return { label: header, order: '', class: '' }
    })

    state.data = headerLessData
    state.tableHeaderIndex = headerIndex
    state.meta = meta
    state.queuedData = headerLessData
    state.tableHeaders = tableHeaders
  },
  loadMore(state) {
    state.tableData = take(state.queuedData, 20 * state.tableDataCount)
    state.tableDataCount++
  },
  resetTableDataCount(state) {
    state.tableDataCount = 1
  },
  setFilter(state, data) {
    state.queuedData = data
  },
  formatHeaderRowIcons(state, index) {
    const headers = state.tableHeaders.map((column, idx) => {
      if (idx === index && column.order === 'asc') column.order = 'desc'
      else if (idx === index) column.order = 'asc'
      else column.order = null
      return column
    })
    state.tableHeaders = headers
  },
  sort(state, { newOrder, index }) {
    state.queuedData = multi.sortRows({
      newOrder,
      index,
      rows: state.queuedData
    })
    state.tableHeaders[index].order = newOrder
  },
  setSearch(state, { input, data, selectedHeaders }) {
    state.queuedData = multi.filterRows({ rows: data, input, selectedHeaders })
  },
  selectColumn(state, columnIndex) {
    const columnHeader = state.tableHeaders[columnIndex]
    columnHeader.class = columnHeader.class
      ? ''
      : 'has-text-primary has-text-weight-bold'
  },
  toggleErrors(state, errorsSelected) {
    state.errorsSelected = errorsSelected
  }
}

export default () => ({
  upload: {
    status: 0,
    progress: null
  },
  history: null,
  headers: null,
  summaryItem: null,
  downloading: null
})

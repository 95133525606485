import { isEmpty } from 'lodash'

export default {
  history: state => state.history,
  hasNoHistory: state => isEmpty(state.history),
  isWaiting: state => state.upload.status === 0,
  isProcessing: state => state.upload.status === 1,
  isComplete: state => state.upload.status === 2,
  headers: state => state.headers,
  summaryItem: state => state.summaryItem,
  downloading: state => state.downloading
}

import * as multi from '../../services'
import { debounce } from 'lodash'

export default {
  async createSummary({ commit }, { filename, token }) {
    const { data } = await multi.getWorkbook(filename, token)
    commit('setData', data)
    commit('loadMore')
  },
  loadMore: debounce(function({ commit }) {
    commit('loadMore')
  }, 250),
  resetQueue({ commit }) {
    commit('resetTableDataCount')
    commit('loadMore')
  },
  setFilter({ commit, getters, dispatch }, filter) {
    const data = getters[filter]
    const errorsSelected = filter.includes('UnavailableRows')

    commit('toggleErrors', errorsSelected)
    commit('setFilter', data)
    dispatch('resetQueue')
  },
  sort({ commit, getters, dispatch }, index) {
    const currentOrder = getters.tableHeaders[index].order
    const newOrder = multi.getNewOrder(currentOrder)

    commit('formatHeaderRowIcons', index)
    dispatch('setFilter', 'errorFreeRows')
    commit('sort', { newOrder, index })
    dispatch('resetQueue')
  },
  search({ commit, getters, dispatch }, input) {
    const data = getters['data']
    const selectedHeaders = getters['selectedHeaders']

    if (input) commit('setSearch', { input, data, selectedHeaders })
    else commit('setFilter', data)

    dispatch('resetQueue')
  }
}

<template>
  <div>
    <section class="section">
      <div class="container">
        <div class="content">
          <p class="title is-5 has-text-dark">
            <span>Get started</span>
          </p>
          <hr class="hr is-light" />
          <p>
            Either drag or select an Excel workbook from your computer to upload
            for bulk processing. It is important that the column names match.
            See the guide below.
          </p>
          <p>Only the "active" sheet in your file will be processed.</p>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="container">
        <div class="columns is-multiline">
          <div class="column is-6">
            <UploadBox />
          </div>
          <div class="column is-6">
            <FieldInfo />
          </div>
        </div>
      </div>
    </section>
    <section class="section" id="multi-history-table">
      <HistoryTable />
    </section>
  </div>
</template>

<script>
import store from '../stores'
import registerModule from '@/store/services'
export default {
  name: 'MultiDashboard',
  components: {
    UploadBox: () => import('modules/multi/components/MultiDashboardUpload'),
    FieldInfo: () => import('modules/multi/components/MultiFieldInfo'),
    HistoryTable: () => import('modules/multi/components/MultiHistoryTable')
  },
  created() {
    registerModule('multi', store)
  }
}
</script>

import Vue from 'vue'
import * as multi from '../../services'

const notify = params => Vue.prototype.$notify(params)

export default {
  async updateStatus({ commit, dispatch }, status) {
    commit('setStatus', status)
    dispatch('fetchHistory')
  },
  async fetchHistory({ commit }) {
    const history = await multi.getHistory()
    commit('setHistory', history)
    return history
  },
  addHeaders({ commit }, headers) {
    commit('addHeaders', headers)
    return headers
  },
  setSummaryItem({ commit }, item) {
    commit('setSummaryItem', item)
    return item
  },
  async download({ commit }, { id, filename }) {
    try {
      commit('toggleDownloading', id)
      const url = `/me/multi/${id}/output`
      return await multi.downloadFile(url, filename)
    } catch {
      notify('Download unavailable')
    } finally {
      commit('toggleDownloading', null)
    }
  }
}

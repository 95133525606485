import * as multi from '../../services'

export default {
  tableHeaderIndex: state => state.tableHeaderIndex,
  meta: state => state.meta,
  data: state => state.data,
  queuedData: state => state.queuedData,
  tableDataCount: state => state.tableDataCount,
  tableData: state => state.tableData,
  tableHeaders: state => state.tableHeaders,
  selectedHeaders: (state, getters) => {
    let selectedHeaders = []
    getters.tableHeaders.forEach((header, index) => {
      if (header.class) {
        selectedHeaders.push(index)
      }
    })
    return selectedHeaders
  },
  vrmUnavailableRows: state => {
    return multi.filterRows({
      rows: state.data,
      input: 'vrm unavailable'
    })
  },
  valuationUnavailableRows: state => {
    const valuationErrrorArray = multi.filterRows({
      rows: state.data,
      input: 'valuation unavailable'
    })
    const mileageErrorArray = multi.filterRows({
      rows: state.data,
      input: "mileage can't be estimated"
    })
    return [...mileageErrorArray, valuationErrrorArray]
  },
  errorFreeRows: (state, getters) => {
    const rows = state.data
      .filter(row => {
        return !getters.vrmUnavailableRows.includes(row)
      })
      .filter(row => {
        return !getters.valuationUnavailableRows.includes(row)
      })
    return rows
  },
  errorsSelected: state => state.errorsSelected
}

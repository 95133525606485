export default {
  setStatus(state, status) {
    const codes = {
      waiting: 0,
      processing: 1,
      completed: 2
    }

    state.upload.status = codes[status]
  },
  setProgress(state, progress) {
    state.progress = progress
  },
  setHistory(state, history) {
    state.history = history
  },
  addHeaders(state, headers) {
    state.headers = headers
  },
  setSummaryItem(state, item) {
    state.summaryItem = item
  },
  toggleDownloading(state, id) {
    state.downloading = id
  }
}

export default () => ({
  data: [],
  queuedData: [],
  tableHeaderIndex: null,
  meta: { valuationUnavailable: 0, vrmUnavailable: 0 },
  tableData: [],
  tableHeaders: [],
  tableDataCount: 1,
  errorsSelected: false
})
